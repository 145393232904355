import React, {useState, forwardRef, useImperativeHandle, useEffect, useRef} from 'react';
import {DisplayStatus} from "./utils";

const AudioPlayer = forwardRef(({ system_message_id, displayStatus }, ref) => {
    const [playing, setPlaying] = useState(false);
    const audio1Ref = useRef(null);
    const audio2Ref = useRef(null);


    useEffect(() => {
        if (displayStatus === DisplayStatus.TYPING) {
            if (audio1Ref.current) {
                audio1Ref.current.pause();
            }
            if (audio2Ref.current) {
                audio2Ref.current.pause();
            }
        }
    }, [displayStatus]);

    // 使用 useImperativeHandle 来暴露特定的方法给父组件
    useImperativeHandle(ref, () => ({
        handleClick(id) {
            setPlaying(true);
            const url1 = `https://open-audio-1256978939.cos.ap-shanghai.myqcloud.com/audio_file_${id}_1.mp3`;
            const url2 = `https://open-audio-1256978939.cos.ap-shanghai.myqcloud.com/audio_file_${id}_2.mp3`;
            playAudioSequence(url1, url2).then(() => setPlaying(false));
        }
    }));

    const tryPlayAudio = async (url) => {
        return new Promise((resolve, reject) => {
            // 使用 fetch API 发送 HEAD 请求
            fetch(url, { method: 'HEAD' })
                .then(response => {
                    // 检查请求是否成功
                    if (response.ok) {
                        const audio = new Audio(url);
                        audio.autoplay = true;
                        // audio.muted = true; // 可能需要静音才能自动播放
                        if(url.endsWith("1.mp3")) {
                            audio1Ref.current = audio;
                        } else if(url.endsWith("2.mp3")) {
                            audio2Ref.current = audio;
                        }
                        audio.onended = () => resolve(true); // 成功播放到结束
                        audio.onerror = (error) => {
                            console.info("播放失败onerror");
                            resolve(false); // 播放失败
                        };
                        audio.play().catch(error => {
                            console.log(`Error playing audio with URL ${url}:`, error); // 播放失败
                            resolve(false); // 播放失败
                        });
                    } else {
                        resolve(false);
                    }
                })
                .catch(error => {
                    resolve(false);
                });
        });
    };

    const playAudioOne = async (url, retryTimes) => {
        // 如果displayStatus改变为TYPING，立即停止尝试播放
        if (displayStatus === DisplayStatus.TYPING) return;
        for (let i = 0; (i < retryTimes) && (displayStatus !== DisplayStatus.TYPING); i++) {
            const success = await tryPlayAudio(url);
            if (success) break;
            await new Promise(resolve => setTimeout(resolve, 1000));
            // 每次循环都检查displayStatus是否已经改变
            if (displayStatus === DisplayStatus.TYPING) break;
        }
    };


    const playAudioSequence = async (url1, url2) => {
        await playAudioOne(url1, 60);
        await playAudioOne(url2, 20);
    };

    return (
        <div>
        </div>
    );
});

export default AudioPlayer;
