
import React, { useState, useEffect } from 'react';
import './BasicInfoForm.css';
import { Button, Input, Select, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserIdFromTokenOrQuery} from './utils';
import { API_URL } from './settings';

function BasicInfoForm(props) {
    const { Option } = Select;
    const [nickname, setNickname] = useState('');
    const [gender, setGender] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const userId = getUserIdFromTokenOrQuery(navigate);
        console.log("userId:", userId);
        if (userId) {
            setCurrentUserId(userId);
        } else {
            navigate("/login");
        }
    }, [location.search, navigate]);

    const handleSubmit = async () => {
        if (nickname.length < 2 || nickname.length > 15) {
            message.error('昵称长度必须在2到15个字符之间');
            return;
        }

        if (!gender) {
            message.error('请选择性别');
            return;
        }

        const sex = gender === 'male' ? 'm' : 'f';
        const url = `${API_URL}/api/users/${currentUserId}/basic_info`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nickname, sex })
            });

            const data = await response.json();

            if (data.status === 'error') {
                message.error(data.detail_message);
            } else if (data.status === 'ok') {
                navigate(`/choices?user_id=${currentUserId}`);
            }
        } catch (error) {
            message.error('提交过程中发生错误: ' + error);
        }
    };

    return (
        <div className="info-form-container">
            <h2>输入基本信息，我能更好了解你~</h2>
            <Input
                value={nickname}
                onChange={e => setNickname(e.target.value)}
                placeholder="昵称: 你希望我怎么称呼你呢?😁"
            />
            <Select
                value={gender}
                onChange={value => setGender(value)}
                placeholder="性别"
            >
                <Option value="male">男</Option>
                <Option value="female">女</Option>
            </Select>
            <Button type="primary" onClick={handleSubmit}>进入</Button>
        </div>
    );
}

export default BasicInfoForm;
