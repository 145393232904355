import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import jwtDecode from 'jwt-decode';
import {handleApiResponse, setAuthorizationHeader, useValidateBigModule} from './utils';
import {message} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import './ChatBox.css';
import {API_URL, WSS_API_URL} from './settings';

function ChatBox() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const chatContainerRef = useRef(null);
    const [isInputFocused, setInputFocused] = useState(false);
    const pollCountRef = useRef(0);
    const [charCount, setCharCount] = useState(0); // 新增字符计数状态
    const inputRef = useRef(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);
    const location = useLocation();
    const is_production = process.env.NODE_ENV === 'production';
    const bigModule = useValidateBigModule();

    useEffect(() => {
        // 在组件卸载时关闭 WebSocket
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]); // 依赖项数组包含 socket，因此当 socket 变化时，上一个 socket 会被关闭
    //设置标题和cookie
    useEffect(() => {
        document.title = is_production ? "暖灯, 用ai陪伴你的情感" : "测试环境";
    }, [is_production]);
    useEffect(() => {
        const tokenRow = document.cookie.split('; ').find(row => row.startsWith('user_token='));
        const token = tokenRow ? tokenRow.split('=')[1] : null;

        if (token) {
            const decodedToken = jwtDecode(token);
            setCurrentUserId(decodedToken.sub);
        } else {
            const queryParams = new URLSearchParams(location.search);
            const userIdFromUrl = queryParams.get('user_id');

            if (userIdFromUrl) {
                setCurrentUserId(userIdFromUrl);
            } else {
                navigate("/login");
            }
        }
    }, [location.search, navigate]);
    // 将滚动条拉到最下方
    useEffect(() => {
        if (chatContainerRef.current) {
            requestAnimationFrame(() => {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            });
        }
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [messages]);

    // 接口1:拉取用户所有消息
    useEffect(() => {
        if (currentUserId) {
            fetch(`${API_URL}/api/users/${currentUserId}/big_module/${bigModule}/messages`)
                .then(handleApiResponse)
                .then(data => {
                    if (data.all_messages.length === 2) {
                        let displayedMessages = [];

                        const displayMessage = (message, speedPerChar) => {
                            return new Promise(resolve => {
                                let charIndex = 0;
                                const intervalId = setInterval(() => {
                                    const newMessage = {
                                        ...message,
                                        message_text: message.message_text.slice(0, charIndex + 1),
                                    };

                                    displayedMessages[displayedMessages.length - 1] = newMessage;
                                    setMessages([...displayedMessages]);

                                    charIndex++;

                                    if (chatContainerRef.current) {
                                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                                    }

                                    if (charIndex === message.message_text.length) {
                                        clearInterval(intervalId);
                                        resolve();
                                    }
                                }, speedPerChar);
                            });
                        };

                        const speedPerChar = 8000 / 120;  // 2000ms per 6 chars

                        // 逐个展示每一条消息
                        const displayAllMessages = async () => {
                            setIsPolling(true); // 开始展示消息之前，设置isPolling为true
                            for (const message of data.all_messages) {
                                displayedMessages.push(message);  // 初始化展示的消息
                                await displayMessage(message, speedPerChar);  // 等待消息展示完成
                            }
                            setIsPolling(false);
                        };

                        displayAllMessages();
                    } else {
                        // 如果不是两条消息，直接展示所有消息
                        setMessages(data.all_messages);
                        if (chatContainerRef.current) {
                            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                        }
                    }
                });
        }
    }, [currentUserId]);

    const formatMessage = (message) => {
        if (!message) return message;
        if (message.endsWith("#END#")) {
            return message.slice(0, -5);
        }
        return message;
    };

    // 接口2发送消息 接口3: poll当前消息
    const sendMessage = () => {
        if (input.length <= 10) {
            message.error('消息长度必须大于10个字符');
            return;
        }

        pollCountRef.current = 0;
        setIsPolling(true);

        const userMessageId = uuidv4();
        const responseMessageId = uuidv4();

        const tempUserMessage = {message_text: input, sender: 'me', message_id: userMessageId};
        const tempResponseMessage = {message_text: '回复中...', sender: 'them', message_id: responseMessageId};

        setMessages(prev => [...prev, tempUserMessage, tempResponseMessage]);
        const headers = setAuthorizationHeader({
            'Content-Type': 'application/json'
        });

        fetch(`${API_URL}/api/users/${currentUserId}/big_module/${bigModule}/messages`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                info: [
                    {sender: "me", message_id: userMessageId, message_text: input},
                    {sender: "them", message_id: responseMessageId}
                ]
            })
        })
            .then(response => {
                // 检查HTTP状态码
                if (response.status === 401) {
                    // 如果状态码是 401，重定向到登录页面
                    window.location.href = "/login";
                    throw new Error("Unauthorized");
                }
                // 如果状态码不是401，继续处理响应
                return response;
            })
            .then(handleApiResponse)  // 使用 handleApiResponse 处理响应体
            .catch(error => {
                console.error("Failed to send message:", error.message);
            });

        const pollForMessage = (responseMessageId, currentUserId) => {
            if (!socket || socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING) {
                const newSocket = new WebSocket(`${WSS_API_URL}/websocket/api/users/${currentUserId}/messages/${responseMessageId}`);
                setSocket(newSocket);

                newSocket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data.message_id === responseMessageId) {
                        const displayedMessage = formatMessage(data.message_text);
                        setMessages((prevMessages) =>
                            prevMessages.map((msg) => {
                                if (msg.sender === 'them' && msg.message_id === responseMessageId) {
                                    return {
                                        ...msg,
                                        message_text: displayedMessage,
                                    };
                                }
                                return msg;
                            })
                        );

                        if (chatContainerRef.current) {
                            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                        }

                        if (data.message_text && data.message_text.endsWith('#END#')) {
                            setIsPolling(false);
                            newSocket.close(); // 关闭 WebSocket 连接
                        }
                    }
                };

                // 当 WebSocket 发生错误时关闭连接
                newSocket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    newSocket.close();
                };

                // 当 WebSocket 被关闭时执行的逻辑
                newSocket.onclose = (event) => {
                    console.log('WebSocket closed:', event);
                };

            }

            else if (socket.readyState === WebSocket.OPEN) {
                socket.send(
                    JSON.stringify({
                        user_id: currentUserId,
                        message_id: responseMessageId,
                    })
                );
            }
        };


        pollForMessage(responseMessageId, currentUserId);
        setInput('');
        if (inputRef.current) {
            inputRef.current.focus();
        }

        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    return (
        <div className="chatbox-container">
            <button
                className="voice-version-btn"
                onClick={() => navigate(`/speak?big_module=${bigModule}`)}
            >
                语音版
            </button>
            <div className={`messages-container ${isInputFocused ? 'faded' : ''}`} ref={chatContainerRef}>
                {messages.map((message, index) =>
                    <div key={index} className={`message ${message.sender}`}>
                        <p>{formatMessage(message.message_text)}</p>
                    </div>
                )}
            </div>
            <div className={`input-container ${isPolling ? 'hidden' : ''}`}>
                <textarea
                    value={input}
                    ref={inputRef}
                    maxLength={200} // 设置字数上限为200
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onChange={
                        e => {
                            setInput(e.target.value);
                            setCharCount(e.target.value.length); // 更新字符计数
                        }
                    }
                    disabled={isPolling}
                />
                <div className="char-counter">
                    ({charCount}/200) {/* 显示字符计数 */}
                </div>
                <button onClick={sendMessage} disabled={isPolling}>发送</button>
            </div>
        </div>
    );
}

export default ChatBox;
