// utils.js

import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';


function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

export function setAuthorizationHeader(headers = {}) {
    const token = getCookie('user_token');
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
}

export function useValidateBigModule() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const bigModule = searchParams.get('big_module');
        const validModules = ["cbt_simple", "morning_journal", "night_journal"];

        // 如果 big_module 不存在或者值不在有效列表中，则进行跳转
        if (!bigModule || !validModules.includes(bigModule)) {
            navigate('/choices');
        }
    }, [navigate, searchParams]);

    // 获取big_module值或者undefined
    const bigModule = searchParams.get('big_module');
    return bigModule;
}

export const getUserIdFromTokenOrQuery = (navigate) => {
    const tokenRow = document.cookie.split('; ').find(row => row.startsWith('user_token='));
    const token = tokenRow ? tokenRow.split('=')[1] : null;

    if (token) {
        const decodedToken = jwtDecode(token);
        return decodedToken.sub;
    } else {
        navigate("/login")
    }
};

export const handleApiResponse = (response) => {
    return response.json().then((body) => {
        if (!body.status || body.status !== 'ok') {
            message.error(body.detail_message || 'An error occurred');
            throw new Error(body.detail_message);
        }
        return body;
    });
};

export const DisplayStatus = {
    INTRO: 'intro',
    TYPING: 'typing',
    WAITING: 'waiting',
    DISPLAYING: 'displaying',
};

export const ErrorCodes = {
    DEFAULT: 'default',
    NO_SPEAK: "no_speak",
    BIG_MODULE_FINISH: "big_module_finish"
}

export const BigModuleChinese = {
    cbt_simple: '认知行为疗法',
    morning_journal: '晨间日记',
    night_journal: '晚间日记',
};
