import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import ChatBox from './ChatBox';
import BasicInfoForm from './BasicInfoForm'; // 确保路径正确
import UserIdInput from './UserIdInput';
import AudioPlayer from './sound';
import AudioRecorder from "./AudioRecorder";
import Speak from "./speak";
import Choices from "./choices"
import LearnAnt from "./learn_ant"

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Navigate to="/login" replace />} />
                    <Route path="/login" element={<UserIdInput />} />
                    <Route path="/users/basic_info" element={<BasicInfoForm />} />
                    <Route path="/choices" element={<Choices />} /> {/* 添加新的路由 */}
                    <Route path="/speak" element={<Speak />} /> {/* 添加新的路由 */}
                    <Route path="/typing" element={<ChatBox />} />
                    <Route path="/sound" element={<AudioPlayer />} /> {/* 添加新的路由 */}
                    <Route path="/uploading" element={<AudioRecorder />} /> {/* 添加新的路由 */}
                    <Route path="/learn" element={<LearnAnt />} /> {/* 添加新的路由 */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
