// UserMessage.js
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {API_URL, WSS_API_URL} from './settings'; // 确保路径正确
import './UserMessage.css';
import {DisplayStatus} from "./utils";

const UserMessage = forwardRef(({userId, bigModule, displayStatus, setDisplayStatus}, ref) => {
    const [messageText, setMessageText] = useState('');
    const [messageStatus, setMessageStatus] = useState('');
    const [messageId, setMessageId] = useState(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        changeUserBigModuleInit();
        fetchUserLatestMessage();
    }, []);


    useEffect(() => {
        const messageSection = document.querySelector('.user-message-section');
        if (messageSection) {
            // 滚动到`user-message-section`的底部
            messageSection.scrollTop = messageSection.scrollHeight;
        }
    }, [messageText]); // 当`messageText`更新时触发

    useEffect(() => {
        if (displayStatus === DisplayStatus.WAITING) {
            setMessageText('');
            document.querySelector('.user-message').style.display = 'none';
            document.querySelector('.message-loading').style.display = 'block';
        } else if (displayStatus === DisplayStatus.DISPLAYING) {
            document.querySelector('.user-message').style.display = 'block';
            document.querySelector('.message-loading').style.display = 'none';
        }
    }, [displayStatus]);

    const changeUserBigModuleInit = async () => {
        try {
            await fetch(`${API_URL}/api/users/${userId}/big_module/${bigModule}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });
        } catch (error) {
            console.error('Error initializing user status:', error);
        }
    };

    const fetchUserLatestMessage = async () => {
        try {
            const response = await fetch(`${API_URL}/api/users/${userId}/big_module/${bigModule}/messages/latest`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.status === 'ok') {
                setMessageText(data.user_latest_message);
                setMessageStatus(data.message_status);
                setMessageId(data.message_id);
            }
        } catch (error) {
            console.error('Error fetching user latest message:', error);
        }
    };

    const formatMessage = (message) => {
        if (!message) return message;
        if (message.endsWith("#END#")) {
            return message.slice(0, -5);
        }
        return message;
    };

    const renderMessageText = (text) => {
        // 按段落分割文本，每个段落之间会有两个换行符
        const paragraphs = text.split('\n');
        // 为每个段落创建一个 JSX 元素<p>，并在它们之间添加额外的空白占位符
        return paragraphs.map((paragraph, index) => (
            <React.Fragment key={index}>
                <p>{paragraph}</p>
                {index < paragraphs.length - 1 && <br/>} {/* 每个段落之后加一个换行，除了最后一个段落 */}
            </React.Fragment>
        ));
    };

    const updateSystemMessage = (responseMessageId) => {
        if (!socket || socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING) {
            const newSocket = new WebSocket(`${WSS_API_URL}/websocket/api/users/${userId}/messages/${responseMessageId}`);
            setSocket(newSocket);

            newSocket.onmessage = (event) => {
                setDisplayStatus(DisplayStatus.DISPLAYING)
                const data = JSON.parse(event.data);
                if (data.message_id === responseMessageId) {
                    const newMessageText = formatMessage(data.message_text);
                    setMessageText(newMessageText); // 更新 messageText 状态
                    if (data.message_text && data.message_text.endsWith('#END#')) {
                        setDisplayStatus(DisplayStatus.INTRO)
                        newSocket.close(); // 关闭 WebSocket 连接
                    }
                }
            };

            newSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
                newSocket.close();
            };

            newSocket.onclose = (event) => {
                console.log('WebSocket closed:', event);
            };
        } else if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({
                user_id: userId,
                message_id: responseMessageId,
            }));
        }
    };

    useImperativeHandle(ref, () => ({
        updateSystemMessage,
    }));

    return (
        <div>
            <div className="user-message">
                {renderMessageText(messageText)}
            </div>
            <div className="message-loading">
                <div className="system-replying">系统正在回复中...</div>
                <div className="cssload-loader-inner">
                    <div className="cssload-cssload-loader-line-wrap-wrap">
                        <div className="cssload-loader-line-wrap"></div>
                    </div>
                    <div className="cssload-cssload-loader-line-wrap-wrap">
                        <div className="cssload-loader-line-wrap"></div>
                    </div>
                    <div className="cssload-cssload-loader-line-wrap-wrap">
                        <div className="cssload-loader-line-wrap"></div>
                    </div>
                    <div className="cssload-cssload-loader-line-wrap-wrap">
                        <div className="cssload-loader-line-wrap"></div>
                    </div>
                    <div className="cssload-cssload-loader-line-wrap-wrap">
                        <div className="cssload-loader-line-wrap"></div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default UserMessage;
