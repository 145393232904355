// detect_activity.js
let timer = null;

export const setupActivityDetector = (timeout = 3600000) => {
    const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            window.location.reload();
        }, timeout);
    };

    // 设置初始计时器
    resetTimer();

    // 添加事件监听器
    window.addEventListener('click', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('mousemove', resetTimer);

    // 返回一个清理函数
    return () => {
        window.removeEventListener('click', resetTimer);
        window.removeEventListener('keydown', resetTimer);
        window.removeEventListener('mousemove', resetTimer);
        clearTimeout(timer);
    };
};
