import React, {useEffect, useRef, useState} from 'react';
import {API_URL} from "./settings";
import './AudioRecorder.css';
import {DisplayStatus, ErrorCodes} from "./utils";
import {message} from 'antd';
import {useNavigate} from "react-router-dom";

const AudioRecorder = ({userId, onUploadSuccess, bigModule, displayStatus, setDisplayStatus}) => {
    const [recording, setRecording] = useState(false);
    const [countDown, setCountDown] = useState(600); // 新增倒计时状态
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // 倒计时逻辑
        if (displayStatus === DisplayStatus.TYPING && countDown > 0) {
            timerRef.current = setTimeout(() => setCountDown(countDown - 1), 1000);
        } else if (countDown === 0) {
            stopRecording(); // 自动停止录音
        }

        return () => clearTimeout(timerRef.current);
    }, [countDown, displayStatus]);


    useEffect(() => {
        if (displayStatus === DisplayStatus.INTRO) {
            const iconWrapper = document.querySelector('.icon-wrapper');
            if (iconWrapper) {
                iconWrapper.style.display = 'flex';
            }
            const countdownRingWrapper = document.querySelector('.countdown-ring');
            if (countdownRingWrapper) {
                countdownRingWrapper.style.display = 'none';
            }
        } else if (displayStatus === DisplayStatus.TYPING) {
            document.querySelector('.icon-wrapper').style.display = 'none';
            document.querySelector('.countdown-ring').style.display = 'flex';
        } else if (displayStatus === DisplayStatus.WAITING) {
            document.querySelector('.countdown-ring').style.display = 'none';
        } else if (displayStatus === DisplayStatus.DISPLAYING) {
            document.querySelector('.icon-wrapper').style.display = 'none';
        }
    }, [displayStatus]);


    const startRecording = async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            setDisplayStatus(DisplayStatus.TYPING);
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.start();

            const audioChunks = [];
            mediaRecorderRef.current.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorderRef.current.addEventListener("stop", async () => {
                const audioBlob = new Blob(audioChunks, {type: 'audio/mp3'});
                postSpeakMessage(audioBlob); // 调用新的上传函数
            });

            setCountDown(60); // 重置倒计时
        } else {
            message.info('当前浏览器不支持录音功能');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // message.success("🤔 声音转录文字中", 1);
            setDisplayStatus(DisplayStatus.WAITING);
            mediaRecorderRef.current.stop();
            clearTimeout(timerRef.current); // 清除定时器
            setCountDown(60); // 重置倒计时
        }
    };

    const postSpeakMessage = async (audioBlob) => {
        const now = new Date();
        const timeString = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds().toString().padStart(3, '0')}`;
        console.log(`初始时间 ${timeString}`);
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.mp3");
         // myMessage 从url的my_message=中拿到
        // Parse the query string from current URL
        const queryParams = new URLSearchParams(window.location.search);
        console.info("query:", queryParams);

        // Check if 'my_message' is in the query string
        if (queryParams.has('my_message')) {
            const myMessage = queryParams.get('my_message');
            formData.append("user_message_text", myMessage);
        }

        try {
            const response = await fetch(`${API_URL}/api/users/${userId}/big_module/${bigModule}/speak_messages`, {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            if (result["error_code"] && result["error_code"] === ErrorCodes.NO_SPEAK) {
                setDisplayStatus(DisplayStatus.INTRO);
                message.info('没有检测到任何声音输入, 请检查麦克风🎤是否开启');
            } else if (result["error_code"] && result["error_code"] == ErrorCodes.BIG_MODULE_FINISH) {
                setDisplayStatus(DisplayStatus.INTRO);
                message.success("你已经完成当前模块所有对话, 即将返回选择其他对话模块", 3);
                setTimeout(() => {
                    navigate(`/choices`);
                }, 3500);
            }
            else {
                console.log('文件上传成功:', result);
                onUploadSuccess(result.system_message_id);
            }
        } catch (error) {
            console.error('上传错误:', error);
            setDisplayStatus(DisplayStatus.INTRO);
        }
    };

    return (

        <div className="audio-recorder">
            <div className={`icon-wrapper`} onClick={startRecording}>
                <button className="record-button">
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6UlEQVR4nO2WOwrCQBRFT5VK7dTliLoBP5sRK8VCXY6fFZhChCAuwmjlB7EURgJXkBAj4iSC5MIr3od74CXMDGR6LwcYAzvAB0aqJa4RYEIR1BLXLgK8TwNsXkQGti6TrZp/+7lWwPIDsKv4WiZk/A5sbQMmA5PSqq8yyin3I6Bb9QrKLzbAG5lVYq7FoXpV5Wsb4IHMJsodwf2Ih8BMs30b4DJwlmEnZq6rmRNQxJLawE3GU6AG5BV1YK5eMNPEslrAMeYAOQANElIJ6AHeE9BTLeilIpPWrfQzsBvzfR/h/gq8SALMX+gOKBWyfJizyGUAAAAASUVORK5CYII="
                        alt="Start Recording"/>
                </button>
            </div>
            <div className={`countdown-ring`} onClick={stopRecording}>
                <svg height="110" width="110">
                    <circle
                        className="countdown-circle"
                        stroke="white" cx="55" cy="55" r="50"/>
                </svg>
            </div>
        </div>
    );
};

export default AudioRecorder;
