import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import './choices.css';
import Navigation from './navigation';  // 引用你的navigation组件

const { Meta } = Card;

const Choices = () => {
    const navigate = useNavigate();

    const navigateToSpeak = (bigModule) => {
        navigate(`/speak?big_module=${bigModule}`);
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour >= 4 && hour < 12) {
            return '上午好!';
        } else if (hour >= 12 && hour < 18) {
            return '下午好!';
        } else {
            return '晚上好!';
        }
    };

    return (
        <div className="choices-container">
            <h1>{getGreeting()}</h1>
            <div className="cards-container">
                <Card
                    hoverable
                    className="choice-card"
                    onClick={() => navigateToSpeak('morning_journal')}
                >
                    <Meta title="晨间日记" />
                    <img alt="" src="https://img.icons8.com/ios-filled/100/sun--v1.png" />
                </Card>
                <Card
                    hoverable
                    className="choice-card"
                    onClick={() => navigateToSpeak('night_journal')}
                >
                    <Meta title="晚间日记" />
                    <img alt="" src="https://img.icons8.com/ios-filled/100/crescent-moon.png" />
                </Card>
                <Card
                    hoverable
                    className="choice-card"
                    onClick={() => navigateToSpeak('cbt_simple')}
                >
                    <Meta title="认知行为疗法" />
                    <img alt="Morning Journal" src="https://img.icons8.com/ios-glyphs/100/rose.png" />
                </Card>
            </div>
            <Navigation />  {/* 使用导航组件 */}
        </div>
    );
};

export default Choices;
