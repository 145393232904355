let API_URL;
let is_production;
let WSS_API_URL;

if (process.env.NODE_ENV === 'development') {
    API_URL = 'http://127.0.0.1:5001';
    is_production = false;
    WSS_API_URL = "ws://127.0.0.1:5001";
} else if (process.env.NODE_ENV === 'production') {
    API_URL = 'https://alov.cc';
    is_production = true;
    WSS_API_URL = "wss://alov.cc";
}

console.log("Is Production Environment:", is_production);

export { API_URL, is_production, WSS_API_URL};
