import React, {useEffect, useState} from 'react';
import {Alert, Button, Input} from 'antd';
import {useNavigate} from 'react-router-dom';
import './UserIdInput.css';
import { API_URL } from './settings';

function UserIdInput(props) {
    const [userId, setUserId] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        let timer;
        if (alertOpen) {
            timer = setTimeout(() => {
                setAlertOpen(false);
            }, 10000);  // 设置10秒后关闭 Alert
        }
        return () => {
            clearTimeout(timer);  // 清除定时器，防止内存泄漏
        };
    }, [alertOpen]);

    const navigate = useNavigate();
    const handleSubmit = () => {
        if (userId.length !== 6) {
            setAlertMessage("user_id 必须为6位数");
            setAlertOpen(true);
            return;
        }

        fetch(`${API_URL}/api/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId
            }),
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "ok") {
                    if (data.finish_intro === false) {
                        navigate(`/users/basic_info?user_id=${data.user_id}`);
                    } else {
                        navigate(`/choices`);
                    }
                } else {
                    setAlertMessage(data.detail_message);
                    setAlertOpen(true);
                }
            });
    }


    return (
        <>
            {alertOpen &&
                <Alert
                    className="alert-position"
                    message="Error"
                    description={alertMessage}
                    type="error"
                    closable
                    onClose={() => setAlertOpen(false)}
                />
            }
            <div className="center-container">
                <Input
                    className="input-spacing"
                    value={userId}
                    onChange={e => setUserId(e.target.value)}
                    placeholder="欢迎使用, 请输入邀请码"
                />
                <Button type="primary" onClick={handleSubmit}>进入</Button>
            </div>
        </>
    );
}

export default UserIdInput;
