import React, { useState } from 'react';

const AudioPlayer = () => {
    const [playing, setPlaying] = useState(false);

    const tryPlayAudio = async (url) => {
        return new Promise((resolve, reject) => {
            const audio = new Audio(url);
            audio.onended = () => resolve(true); // 当音频播放结束时，解决 Promise
            audio.onerror = (error) => {
                resolve(false); // 如果有错误，解决 Promise 但返回 false
            };
            audio.play().catch(error => {
                resolve(false); // 捕获播放错误
            });
        });
    };

    const playAudioOne = async (url, retryTimes) => {
        for (let i = 0; i < retryTimes; i++) {
            const success = await tryPlayAudio(url);
            if (success) break;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    };

    const handleClick = async () => {
        setPlaying(true);
        const url1 = "https://open-audio-1256978939.cos.ap-shanghai.myqcloud.com/audio_file_2891715d-bcf3-4108-b292-64a96b4fea89_1.mp3";
        const url2 = "https://open-audio-1256978939.cos.ap-shanghai.myqcloud.com/audio_file_2891715d-bcf3-4108-b292-64a96b4fea89_2.mp3";
        await playAudioOne(url1, 60);
        await playAudioOne(url2, 60);
        setPlaying(false);
    };

    return (
        <div>
            <button onClick={handleClick} disabled={playing}>
                {playing ? 'Playing...' : 'Play Audio'}
            </button>
        </div>
    );
};

export default AudioPlayer;
