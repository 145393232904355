import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './navigation.css';
import {message} from 'antd';

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className='nav-container'>
            <div
                className={`nav-icon-container ${location.pathname === '/choices' ? 'nav-icon-active' : ''}`}
                onClick={() => navigate('/choices')}
            >
                <img
                    className='nav-icon'
                    src="https://img.icons8.com/ios-filled/100/home.png"
                    alt="Home"
                />
                <span>主页</span>
            </div>
            <div
                className={`nav-circle-icon-container ${location.search === '?big_module=cbt_simple' ? 'nav-icon-active' : ''}`}
                onClick={() => navigate('/speak?big_module=cbt_simple')}
            >
                <img
                    className='nav-circle-icon'
                    src="https://img.icons8.com/ios-filled/100/add--v1.png"
                    alt="Add"
                />
            </div>
            <div
                className={`nav-icon-container ${location.pathname === '/people' ? 'nav-icon-active' : ''}`}
                onClick={() => message.success("个人页面即将推出, 敬请期待")}
            >
                <img
                    className='nav-icon'
                    src="https://img.icons8.com/ios/100/user--v1.png"
                    alt="User"
                />
                <span>个人</span>
            </div>
        </div>
    );
};

export default Navigation;
