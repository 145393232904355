// Speak.js
import React, {useEffect, useRef, useState} from 'react';
import AudioRecorder from './AudioRecorder';
import AudioPlayer from './AudioPlayer';
import UserMessage from './UserMessage';
import {useLocation, useNavigate} from 'react-router-dom';
import {BigModuleChinese, DisplayStatus, getUserIdFromTokenOrQuery, useValidateBigModule} from './utils';
import {setupActivityDetector} from './detect_activity';
import {Layout, message} from 'antd';
import './speak.css';

const {Header, Content} = Layout;

const Speak = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = getUserIdFromTokenOrQuery(navigate);
    const [systemMessageId, setSystemMessageId] = useState(null);
    const audioPlayerRef = useRef(null);
    const userMessageRef = useRef(null);
    const [displayStatus, setDisplayStatus] = useState(DisplayStatus.INTRO);
    const bigModule = useValidateBigModule();

    useEffect(() => {
        // 使用detectActivity函数来检测用户活动
        const cleanupActivityDetector = setupActivityDetector();

        return () => {
            // 清理活动检测器
            cleanupActivityDetector();
        };
    }, []);


    message.config({
        top: 120,        // 消息从顶部显示时距离顶部的位置，默认是 24px
        duration: 6,     // 默认自动关闭延时，单位秒，默认是 3
        maxCount: 1,     // 最大显示数，默认是提示框同时显示的最大数量
        rtl: true,       // 是否开启 RTL 模式，默认是 false
    });

    // 根据状态变化获取不同的record instruction文本
    const getRecordInstruction = () => {
        switch (displayStatus) {
            case DisplayStatus.INTRO:
                return "点击开始语音对话";
            case DisplayStatus.TYPING:
                return "倒计时结束前, 按下按钮发送对话";
            case DisplayStatus.WAITING:
            case DisplayStatus.DISPLAYING:
                return "系统正在回复中";
            default:
                return "点击开始语音对话";
        }
    };

    const handleUploadSuccess = (id) => {
        message.success("😁声音转录文字成功! 等待系统回复", 2);
        setSystemMessageId(id);
        userMessageRef.current?.updateSystemMessage(id);
        audioPlayerRef.current?.handleClick(id);
    };


    const goToTypingPage = () => {
        navigate(`/typing?big_module=${bigModule}`,);
    };

    const goToChoicesPage = () => {
        navigate(`/choices`,);
    }

    return (
        <Layout className="speak-container">
            <div className="speak-header">
                <button className="back-button" onClick={goToChoicesPage}>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZ0lEQVR4nO2WMQqAQAwE5xMR/f9LrETRxit8jsdJbKwsNIK306UaWJYlIMR9GmABeoKlCdiBMUpqwOrSDWglfRLF+ypWTXsLs0uTjwW/F9sl6k7yCEyxo8J99PpMkeJTXhZuOC5RPRnBEDtxjsnsowAAAABJRU5ErkJggg=="
                        alt="Back"/>
                </button>
                <div className="title">{BigModuleChinese[bigModule]}</div>
            </div>
            <Content className="speak-content">
                <div className="user-message-section">
                    <UserMessage
                        ref={userMessageRef}
                        userId={userId}
                        bigModule={bigModule}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                    />
                </div>
                <div className="answer-area">
                    <p className="record-instruction">{getRecordInstruction()}</p>
                    <AudioRecorder
                        userId={userId}
                        onUploadSuccess={handleUploadSuccess}
                        bigModule={bigModule}
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                    />
                    <AudioPlayer
                        ref={audioPlayerRef}
                        system_message_id={systemMessageId}
                        displayStatus={displayStatus}
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default Speak;
